function picto_focus(img) {
    img.attr('src', $(img.context).data('hoverImg'));
}

function picto_blur(img) {
    img.attr('src', $(img.context).data('img'));
}

function valid(input) {
    removeValidStateIcon(input);
    addValidIcon(input);
    removeErrorClasses(input);
    addValidClasses(input);
}

function invalid(input) {
    removeValidStateIcon(input);
    addInvalidIcon(input);
    removeValidClasses(input);
    addErrorClasses(input);
}

function addValidIcon(input) {
    input.after("<span class='glyphicon glyphicon-ok form-control-feedback'></span>");
}

function addInvalidIcon(input) {
    input.after("<span class='glyphicon glyphicon-remove form-control-feedback'></span>");
}

function removeValidStateIcon(input) {
    input.next().remove();
}

function addErrorClasses(input) {
    input.parent().parent().addClass("has-feedback");//On cible la div form group parente de l'input
    input.parent().addClass('has-error');
    input.parent().prev().addClass("has-error");//On cible le conteneur du sprite
    input.parent().prev().children(0).addClass("has-error");//On cible le sprite
    input.addClass('form-error has-error');
}

function removeErrorClasses(input) {
    input.parent().parent().removeClass("has-feedback");
    input.parent().removeClass('has-error');
    input.parent().prev().removeClass("has-error");
    input.parent().prev().children(0).removeClass("has-error");
    input.removeClass('form-error has-error');
}

function addValidClasses(input) {
    input.parent().parent().addClass("has-success has-feedback");
}

function removeValidClasses(input) {
    input.parent().parent().removeClass("has-success has-feedback");
}

function blank(input) {
    removeErrorClasses(input);
    removeValidClasses(input);
    removeValidStateIcon(input);
}

jQuery.fn.initializeHabitat = function () {
    //switch src des picto sur un hover
    $('.picto-hover').hover(function () {
        if (!$(this).hasClass('picto-active')) {
            var img = $('.picto img', $(this));
            picto_focus(img);
        }
    }, function () {
        if (!$(this).hasClass('picto-active')) {
            var img = $('.picto img', $(this));
            picto_blur(img);
        }
    });

    $('.picto-form').on('click', function () {
        if ($(this).hasClass('picto-active')) {
            $(this).removeClass('picto-active');
            $('#' + $(this).data('formTarget')).val('');
        } else {
            $('.picto-active').removeClass('picto-active').each(function () {
                var img = $('.picto img', $(this));
                picto_blur(img);
            });
            var img = $('.picto img', $(this));
            $(this).addClass('picto-active');
            $('#' + $(this).data('formTarget')).val($(this).data('formValue'));
            picto_focus(img);
        }
    });

    $('.form-ajax-content .form-ajax').each(function () {
        var $form = $(this);
        $form.ajaxForm({
            beforeSubmit: function () {
                $form.find('input[type=\'submit\']')
                    .prop('disabled', true)
                    .attr('value', 'En cours ...');
            },
            success: function (html) {
                $parent = $form.closest('.form-ajax-content');
                if ($parent.attr('data-replace')) {
                    $parent.html(html);
                } else {
                    $parent.append(html);
                }
                $parent.initialize()
                    .initializeHabitat();
                $form.find('input[type=\'submit\']')
                    .prop('disabled', false)
                    .attr('value', 'ok');
            }
        });
    });
};

jQuery(document).ready(function () {
    jQuery('#category_url').select2({
        language: {
            noResults: function () {
                return "Aucun résultat";
            },
        },
    });
});